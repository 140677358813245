
import request from '@/utils/request'
// 项目详情岗位数据接口
export function projectInfo(data) {
  return request({
    url: '/zk/project/projectInfo',
    method: 'get',
    params: data
  })
}

// 通过项目id获取数据 （登录才能调用）
export function getAllProjectInfo(data) {
  return request({
    url: '/zk/project/getAllProjectInfo',
    method: 'post',
    data: data
  })
}

// 账户密码登录
export function memberPwdLogin(data) {
  return request({
    url: '/zk/memberPwdLogin',
    method: 'post',
    data: data
  })
}

// 短信登录
export function smsLogin(data) {
  return request({
    url: '/zk/smsLogin',
    method: 'post',
    data: data
  })
}

// 发送短信
export function sendCode(data) {
  return request({
    url: '/zk/sendCode',
    method: 'post',
    data: data
  })
}

// 注册
export function memberRegister(data) {
  return request({
    url: '/zk/memberRegister',
    method: 'post',
    data: data
  })
}

// 编辑密码
export function updatePwd(data) {
  return request({
    url: '/zk/updatePwd',
    method: 'post',
    data: data
  })
}

// 我的报名列表
export function myApplicationList(data) {
  return request({
    url: '/zk/participants/myApplicationList',
    method: 'post',
    data: data
  })
}

// 提交报名
export function signPost(data) {
  return request({
    url: '/zk/participants/signPost',
    method: 'post',
    data: data
  })
}

// 查询栏目列表
export function columnList(data) {
  return request({
    url: '/zk/column/columnList',
    method: 'get',
    params: data
  })
}

// 查询栏目列表- 下属内容
export function noticesList(data) {
  return request({
    url: '/zk/notices/frontList',
    method: 'post',
    data: data
  })
}
// 查询更多栏目
export function frontColumnList(data) {
  return request({
    url: '/zk/column/frontColumnList',
    method: 'post',
    data: data
  })
}

// 通过id获取内容详情
export function getNoticesId(data) {
  return request({
    url: `/zk/notices/getNotices/${data}`,
    method: 'get',
  })
}
// 报考日历
export function applyRecord(data) {
  return request({
    url: `/zk/participants/applyRecord`,
    method: 'post',
    data: data
  })
}
// 个人审核记录
export function personalAuditRecord(data) {
  return request({
    url: `/zk/participants/personalAuditRecord`,
    method: 'post',
    data: data
  })
}
// 个人基础信息
export function getBasicInformation() {
  return request({
    url: `/zk/info/getBasicInformation`,
    method: 'get',
  })
}
// 个人基础信息 - 提交
export function basicInformation(data) {
  return request({
    url: `/zk/info/basicInformation`,
    method: 'post',
    data: data
  })
}
// 个人中心 - 招考推荐
export function recommendList(data) {
  return request({
    url: `/zk/column/recommend`,
    method: 'post',
    data: data
  })
}
// 删除图片
export function deleteFile(data) {
  return request({
    url: `/zk/project/deleteFile`,
    method: 'post',
    data: data
  })
}
// 专业接口 - 废弃
export function getMajorList() {
  return request({
    url: `/zk/major/list`,
    method: 'post',
  })
}
// 点击未读
export function updateReadStatus(id) {
  return request({
    url: `/zk/participants/updateReadStatus/${id}`,
    method: 'get',
  })
}
// 点击实名认证
export function realNamePayment() {
  return request({
    url: `/zk/order/realNamePayment`,
    method: 'get',
  })
}
// banner列表
export function bannerList(data) {
  return request({
    url: '/zk/banner/list',
    method: 'post',
    data: data
  })
}
// 心跳获取套餐购买状态
export function queryPayStatus(data) {
  return request({
    url: '/zk/order/queryPayStatus',
    method: 'post',
    data: data
  })
}
// 取消订单
export function canalOrder(data) {
  return request({
    url: '/zk/order/canalOrder',
    method: 'post',
    data: data
  })
}
// 第二部扫脸认证
export function createFaceUrl(data) {
  return request({
    url: '/tencentCloudFace/createFaceUrl',
    method: 'post',
    data: data
  })
}
// 第二部的心跳
export function baseResult(data) {
  return request({
    url: '/tencentCloudFace/getBaseResult',
    method: 'post',
    data: data
  })
}
// 报名完成后点击支付二维码
export function registrationFee(data) {
  return request({
    url: '/zk/participants/registrationFee',
    method: 'post',
    data: data
  })
}
// 用户会员购买套餐列表
export function selectMemberPackage() {
  return request({
    url: '/zk/package/selectMemberPackage',
    method: 'get',
  })
}

// 用户端套餐列表
export function createMemberOrder(data) {
  return request({
    url: '/zk/order/createMemberOrder',
    method: 'post',
    data: data
  })
}

// 用户端购买套餐记录列表
export function memberOrderList(data) {
  return request({
    url: '/zk/order/memberOrderList',
    method: 'post',
    data: data
  })
}

// 专业接口 列表
export function getMajorListAll(type) {
  return request({
    url: `/zk/major/majorList/${type}`,
    method: 'get',
  })
}
// 岗位列表
export function industryList() {
  return request({
    url: '/zk/industry/list',
    method: 'get',
  })
}
// 省市区内容
export function proCityList() {
  return request({
    url: '/zk/ProvinceCity/getProCityList',
    method: 'get',
  })
}



// 后台登录
export function adminLogin(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}
// 新增后台用户
export function addUser(data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}
// 新增后台用户
export function adminSmsLogin(data) {
  return request({
    url: '/smsLogin',
    method: 'post',
    data: data
  })
}
// 获取logo设置
export function getUserLogo(data) {
  return request({
    url: "/zk/logo/getUserLogo",
    method: "get",
    params: data,
  });
}

