<template>
    <MyModal
      :show="show"
      modalTitle="易招考个人信息保护协议"
      modalWidth="800px"
      @setFalse="setFalse"
      :showFooterTow="true"
    >
    <div slot="content" class="tal">
        <div class="cont">
          <p>
            感谢您使用“易招考”产品及服务！“易招考”由江西人力大数据有限公司所有并运营。我们按照《中华人民共和国民法典》及《中华人民共和国个人信息保护法》等相关法律法规将制定本隐私政策，旨在确保您的个人信息安全可控。本隐私政策将充分告知我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息。在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，确保您充分理解和同意之后再开始使用我们的产品和服务。您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权。
          </p>
          <p>本政策将帮助您了解以下内容：</p>
          <p>一、我们如何收集和使用您的个人信息</p>
          <p>二、我们如何使用Cookie技术</p>
          <p>三、我们如何共享、转让和公开披露您的个人信息</p>
          <p>四、我们如何存储和保护您的个人信息</p>
          <p>五、您如何管理您的个人信息</p>
          <p>六、第三方收集和使用您的个人信息情况</p>
          <p>七、本政策如何更新</p>
          <p>八、如何联系我们</p>
          <p>一、我们如何收集和使用您的个人信息</p>
          <p>（一）我们如何收集您的个人信息</p>
          <p>
            在您使用“易招考”服务过程中，我们会收集您在使用服务过程中主动输入或因使用服务而产生的信息。我们会为您提供的各项具体功能场景及可能涉及到的信息收集与使用包括：
          </p>
          <p>1、注册、登录及第三方账号登录</p>
          <p>
            为了注册创建账号和密码等需要，我们将收集使用您的手机号、验证码、密码。我们同时提供第三方账号登录功能，如您选择使用该功能，我们将会收集并使用第三方账号信息、手机号、头像、昵称、性别、地区、邮箱，用于使用第三方账号授权登录。
          </p>
          <p>2、设置头像、昵称、性别、生日、地区、个性签名</p>
          <p>
            为完善账号信息，我们为您提供设置头像、昵称、性别、地区、个性签名等功能服务，如您使用该服务，我们将收集使用您的头像、昵称、性别、地区、个性签名等信息。
          </p>
          <p>3、信息填写</p>
          <p>
            为了获得更好的报名，您需要填写以下信息的部分或全部。
            该部分的信息分为必填及非必填信息。完成必填信息后您即可进行报名，填写更多信息将有助于招聘企业了解您更多的信息，有助于提升您的报名效果。
          </p>
          <p>
            【个人基本信息】必填：姓名、性别、出生年月、手机号码、毕业院校、毕业时间；选填：邮箱、头像、政治面貌；
          </p>
          <p>
            【教育经历】必填：学校、学历/学位、在校时间、专业、是否全日制、是否留学经历；选填：专业描述；
          </p>
          <p>【校内荣誉】选填：获得奖项名称、获得时间、级别；</p>
          <p>【校内职务】选填：职务名称、任职时间、职务描述；</p>
          <p>【个人优势】选填：个人优势；</p>
          <p>
            【实习/项目经验】选填：项目时间、项目名称、项目描述、所属公司、责任描述；
          </p>
          <p>【证书】选填：证书名称、获得时间、成绩；</p>
          <p>【技能/语言】选填：技能/语言名称、熟练（掌握）程度；</p>
          <p>
            【培训经历】选填：培训课程、培训时间、培训机构、培训地点、培训描述；
          </p>
          <p>
            【个人作品】选填：作品名称、上传方式（作品图片/作品链接）、作品描述；
          </p>
          <p>【获奖情况】选填：获奖时间、奖项名称、奖项级别、奖项描述；</p>
          <p>【家庭成员】选填：成员姓名、成员关系、工作单位、职位；</p>
          <p>【培训经历】选填：课程名称、机构名称、培训时间、培训内容；</p>
          <p>
            【实习经历】选填：实习开始日期、实习结束日期、实习公司名称、实习公司所属行业、实习职位、工作描述；
          </p>
          <p>【获得证书】选填：证书名称、获得日期；</p>
          <p>
            【学术成果及发表论文】选填：成果及论文名称、发布时间、所属期刊；
          </p>
          <p>
            【在校职务】选填：在校组织名称、在校职位名称、在校职务描述、职务开始时间、职务结束时间；
          </p>
          <p>
            【项目经验】选填：项目开始日期、项目结束日期、担任职位/角色、项目单位、项目描述、参加人数、职责与业绩；
          </p>
          <p>
            【知识产权】选填：获得专利名称、获得著作权名称、获得执业资格证名称；
          </p>
          <p>
            【其他】选填：是否愿意服从公司分配、招聘信息来源渠道、自我评价。
          </p>
          <p>
            您知悉并同意，在您选择公开范围内的平台用户可以查看您的以获取您填写或上传的信息。
          </p>
          <p>4、实名认证</p>
          <p>
            当您注册成为易招考用户以后，您可以根据需要选择使用实名认证功能，来提升您求职的真实性和可信赖度。实名认证需要您提供真实姓名、手机号、身份证号码，当您使用的手机号，实名信息并非您本人时，还需要您提供面部识别信息，以准确核验您的身份，此类信息属于实名认证功能的必需信息，如您拒绝提供上述信息，可能我们无法向您正常提供实名认证服务，但不影响您使用除实名认证的平台其他产品和/或服务。
          </p>
          <p>5、即时通讯交流。</p>
          <p>
            为了提供便捷高效的即时通讯交流，该功能可能会收集并使用沟通聊天内容、语音通话状态信息（非通话内容）、位置信息等个人信息内容，用于在线沟通交流、音视频通话、发送和共享位置。
          </p>
          <p>6、客服沟通与投诉处理</p>
          <p>
            账号信息、与客服联系的工单及内容、联系方式、沟通记录，用于客服提供服务。
          </p>
          <p>7、提供安全保障</p>
          <p>
            设备信息、服务日志信息、账号信息、交易信息，用于提供服务安全保障，预防钓鱼网站、网络诈骗、安全漏洞、病毒和网络攻击、网络侵入。
          </p>
          <p>8、其他</p>
          <p>
            我们在向您提供其他业务功能时，会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。我们会按照本政策以及相应的用户协议约定收集、使用、存储、对外提供及保护您的信息。
          </p>
          <p>
            如您不同意上述某一服务所需收集的信息，可能无法获得相应的服务，但不影响您正常使用“易招考”的其他功能或服务。
          </p>
          <p>（二）我们如何使用您的个人信息</p>
          <p>
            为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，并保障您的账户和资金安全，我们会在以下情形中使用您的信息：
          </p>
          <p>
            1.我们会根据本政策的约定并为实现我们的服务或功能对所收集的您的个人信息进行使用。
          </p>
          <p>
            2.为了使您知晓使用易招考服务的状态，我们会向您发送服务提醒。您可以通过手机系统设置中的通知设置关闭服务提醒，也可以通过通知设置重新开启服务提醒。
          </p>
          <p>
            3.为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范或禁止非法活动、降低风险、存档和备份用途。
          </p>
          <p>4.根据法律法规或监管要求向相关部门进行报告。</p>
          <p>5.邀请您参与我们服务、产品或功能有关的客户调研。</p>
          <p>（三）征得授权同意的例外</p>
          <p>
            根据相关法律法规、监管要求，以下情形我们会处理您的相关个人信息而无需另行征求您的授权同意：
          </p>
          <p>1.与国家安全、国防安全直接相关的；</p>
          <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
          <p>4.出于维护您或其他个人的生命、财产安全所必需的；</p>
          <p>
            5.所处理的个人信息是您自行公开或属于其他已经合法公开的个人信息的；
          </p>
          <p>6.为履行我们法定职责或法定义务所必需的；</p>
          <p>
            7.为订立、履行您作为一方当事人的合同所必需的，或按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需的；
          </p>
          <p>
            8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
          </p>
          <p>9.法律法规及监管规定要求的其他情形。</p>
          <p>（四）我们从第三方获得您个人信息的情形</p>
          <p>
            我们可能从第三方（如微信、支付宝等）获取您授权共享的账户信息（头像、昵称），并在您同意本政策后将您的第三方账户与您的易招考用户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。
          </p>
          <p>
            为了给您提供快捷的登录、注册、转账支付类的资金交易服务，保障您的交易安全、尽可能防范您的账户被他人不法侵害，我们可能通过依法设立的电信运营商等第三方机构（包括但不限于中国电信、中国移动、中国联通等外部合作机构）获取您的设备手机号及当前所在城市信息，并依据与上述第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律法规、监管规定的前提下，使用您的这些个人信息。
          </p>
          <p>二、如何使用Cookie和同类技术的服务</p>
          <p>
            为确保易招考正常运转，我们会在您移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，易招考能够存储您的偏好数据。我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除移动设备上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。
          </p>
          <p>
            同时大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。如您进行这些修改，可能无法使用依赖于Cookie的服务或相应功能。
          </p>
          <p>三、我们如何共享、转让和公开披露您的个人信息</p>
          <p>（一）共享</p>
          <p>
            我们不会向其他公司、组织和个人共享您的个人信息，但以下情况除外：
          </p>
          <p>1.事先获得您的明确同意。</p>
          <p>
            2.如业务需要对外共享您的个人信息，我们会向您告知共享个人信息的目的、数据接收方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，并取得您的单独同意。涉及敏感信息的，除上述之外，我们还会向您告知处理敏感个人信息的必要性以及对您个人权益的影响。
          </p>
          <p>
            3.在特定情况下，我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
          </p>
          <p>
            4.我们可能会向合作方（主要是推送服务或移动设备厂商）提供您的部分账户信息及交易信息，以便及时向您的移动设备发送账户资金变动及相关交易通知。
          </p>
          <p>（二）转让</p>
          <p>
            我们不会向其他公司、组织和个人转让您的个人信息，但以下情况除外：
          </p>
          <p>1.事先获得您的明确同意。</p>
          <p>2.根据法律法规或强制性的行政或司法要求。</p>
          <p>
            3.根据法律法规、监管规定和商业惯例，在合并、收购、资产转让等类似交易中，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，或要求该等公司、组织重新向您征求授权同意。
          </p>
          <p>（三）公开披露</p>
          <p>
            1.我们不会公开披露您的个人信息，如确需披露，我们会获取您的单独同意，并告知您披露个人信息的目的、类型；涉及敏感信息，除上述外，还会告知您敏感信息的内容，处理敏感个人信息的必要性以及对您个人权益的影响，并事先征得您的明示同意。
          </p>
          <p>
            2.依据法律、法律程序、诉讼或政府主管部门的要求，我们可能会公开披露您的个人信息。
          </p>
          <p>（四）征得授权同意的例外</p>
          <p>
            根据相关法律法规、监管规定，我们可能会共享、转让、公开披露用户信息而无需事先征得您授权同意：
          </p>
          <p>1.与国家安全、国防安全直接相关的；</p>
          <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
          <p>4.出于维护您或其他个人的生命、财产安全所必需的；</p>
          <p>
            5.所处理的个人信息是您自行公开或属于其他已经合法公开的个人信息的；
          </p>
          <p>6.为履行我们法定职责或法定义务所必需的；</p>
          <p>
            7.为订立、履行您作为一方当事人的合同所必需的，或按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需的；
          </p>
          <p>
            8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
          </p>
          <p>9.法律法规及监管规定要求的其他情形。</p>
          <p>四、我们如何存储和保护您的个人信息</p>
          <p>（一）存储</p>
          <p>
            1.我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
          </p>
          <p>
            2.我们仅在法律法规要求的期限内，以及为实现本政策声明的目的所必须的时限内保留您的个人信息。
          </p>
          <p>（二）保护</p>
          <p>
            1.我们已使用符合业界标准的安全防护措施保护您提供的个人信息，我们将尽力防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如：我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，限定只有授权人员才可访问个人信息。
          </p>
          <p>
            2.如我们提供的全部或部分“易招考”业务停止运营，我们相关产品或服务将通过公告等形式向您进行告知，同时停止相关产品或服务对您个人信息的收集等操作，保护您的个人信息安全。如因技术故障、网络攻击、自然灾害及事故、人为因素等各种原因造成全部或部分“易招考”业务中断，我们将采取应急处置和恢复措施予以应对，尽快恢复服务。
          </p>
          <p>3.我们已通过国家网络安全等级保护的测评和备案。</p>
          <p>
            4.我们会采取一切合理可行的措施，确保未收集与您所办理业务无关的个人信息。
          </p>
          <p>
            5.请您理解，由于各种因素有可能出现我们无法合理预见、防范、避免、控制的意外情况。互联网并非绝对安全的环境，请使用复杂密码，协助我们保证您的账户安全。
          </p>
          <p>
            6.如发生个人信息安全事件，我们将按照法律法规的要求，及时采取措施进行化解，同时将事件相关情况以邮件、信函、电话、推送通知等方式中的一种或多种告知您。同时，我们还将依据监管规定，上报个人信息安全事件处置情况。
          </p>
          <p>（三）我们如何保护未成年人信息</p>
          <p>
            1、未成年人使用我们服务，必须在其父母或者其他监护人的监护下进行。我们将根据国家相关法律法规的规定保护未成年人的个人信息的保密性及安全性。
          </p>
          <p>
            2、如您为未成年人，请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用“易招考”内提供的服务或向我们和第三方提供您的信息。对于经父母或监护人同意而使用您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或共享此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
          </p>
          <p>
            3、如您为未成年人的父母或监护人，当您对您所监护的未成年人的个人信息处理存在疑问时，请通过本政策中的联系方式联系我们。
          </p>
          <p>
            4、如您为14周岁以下的儿童，请在征得您父母或监护人同意的前提下使用“易招考”内提供的服务或向我们和第三方提供您的信息。对于经父母或监护人同意而使用您的信息的情况，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
          </p>
          <p>五、您如何管理您的个人信息</p>
          <p>
            按照中国相关的法律法规和监管规定，我们保障您对自己的个人信息行使以下权利：
          </p>
          <p>（一）访问、更正及更新您的个人信息</p>
          <p>
            您有权通过我们“易招考”相应程序访问及更正、更新您的个人信息，法律法规、监管政策另有规定的除外。您有责任及时更新您的个人信息。在您修改个人信息之前，我们会验证您的身份。
          </p>
          <p>（二）删除您的个人信息</p>
          <p>
            您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括创建的信息、绑定的银行卡、消息记录、缓存记录。
          </p>
          <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
          <p>1.如果我们处理个人信息的行为违反法律法规。</p>
          <p>
            2.如果我们收集、使用您的个人信息，不符合本政策所述授权例外情形，却未征得您的同意。
          </p>
          <p>3.如果我们处理个人信息的行为违反了与您的约定。</p>
          <p>4.如果您不再使用我们的产品或服务。</p>
          <p>5.如果我们不再为您提供产品或服务。</p>
          <p>
            如我们决定响应您的删除请求，我们还将同时通知从我们获得您个人信息的实体，要求其及时删除，除非法律法规或监管政策另有规定，或这些实体获得您的独立授权。
          </p>
          <p>
            当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          </p>
          <p>（三）改变您授权同意的范围或撤回您的授权</p>
          <p>
            您可以通过手机系统权限设置、“易招考”程序设置、联系客服等方式改变部分您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销我们账号的方式，撤回我们继续收集您个人信息的全部授权。
          </p>
          <p>
            请您注意，您注销我们账号的同时，将视同您撤回了对本政策的同意，我们将不再收集相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理活动的效力。
          </p>
          <p>（四）响应您的请求</p>
          <p>
            如果您无法通过上述方式访问、更正或删除您的用户信息，或您需要访问、更正或删除您在使用我们服务或功能时所产生的其他用户信息，或您认为我们存在任何违反法律法规或与您关于用户信息的收集或使用的约定，您均可通过本政策中的联系方式与我们取得联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。
          </p>
          <p>
            此外，根据相关法律法规、监管要求等规定,以下情形中遇到国家有权机关或者监管机关要求的，或者存在以下约定其他情形的，我们将可能无法响应您的请求：
          </p>
          <p>1．与国家安全、国防安全相关的。</p>
          <p>2．与公共安全、公共卫生、重大公共利益相关的。</p>
          <p>3．与犯罪侦查、起诉、审判和判决执行等相关的。</p>
          <p>4．有充分证据表明您存在主观恶意或滥用权利的。</p>
          <p>
            5．响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
          </p>
          <p>6．涉及我们或第三方商业秘密的。</p>
          <p>六、第三方收集和使用您的个人信息情况</p>
          <p>
            当您在“易招考”中使用第三方提供的服务时，第三方可能会从我们获取您的昵称、头像、位置、姓名、证件类型、证件号码、证件有效期、手机号码信息及其他提供第三方服务所必须的信息；在经过您的明示同意后，第三方可获取您的以上信息。在将信息提供给第三方前，我们将尽法律上合理的义务评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规和签署的法律文件，要求第三方对您的信息采取保护措施。如您拒绝第三方在提供服务时收集、使用或者传递上述信息，将可能会导致您无法在“易招考”中使用第三方的相应服务，但这不影响您使用“易招考”的其他功能。
          </p>
          <p>
            对于您在使用第三方提供的服务时主动提供给第三方的相关信息，我们将视为您允许该第三方获取上述此类信息；对于您在使用第三方服务时产生的信息，应由您与该第三方依法约定上述信息的收集和使用事项。
          </p>
          <p>
            您理解，第三方提供的服务由该第三方独立运营并独立承担全部责任。因第三方所提供服务或其使用您的信息产生的纠纷，或其违反相关法律法规或本协议约定，或您在使用其服务过程中遭受的损失，请您与该第三方协商解决。对于需由第三方解决的问题，我们将尽法律上合理的义务推动第三方解决。
          </p>
          <p>七、本政策如何更新</p>
          <p>
            根据国家法律法规、监管政策变化及服务运营需要，我们将对本政策及相关规则不时地进行修改，修改后的内容会通过我们“易招考”公布，公布后即生效，并取代此前相关内容。您应不时关注相关公告、提示信息及协议、规则等相关内容的变动。本政策更新后，“易招考”将在登录页提示您再次确认同意
            。您知悉并确认，如您不同意更新后的内容，应立即停止使用相应服务，我们将停止收集您的相关个人信息；如您继续使用服务，即视为同意接受更新内容。
          </p>
          <p>本政策更新时间为2024年4月10日</p>
          <p>本政策生效时间为2024年4月10日</p>
          <p>八、如何联系我们</p>
          <p>
            我们设立了个人信息保护小组，如您对本政策内容有任何疑问、意见或建议，您可以通过【】与我们联系（请您注明与【易招考平台】有关，并说明具体事实情况）。我们将会在收到您的信息后尽快回复您，但需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。
          </p>
        </div>
      </div>
      <div slot="footer-content" class="tac">
        <el-button size="small" @click="setFalse">关 闭</el-button>
      </div>
    </MyModal>
</template>
<script>
export default {
    name:'ZhuceXieyi',
    methods:{
        setFalse(){
            this.$emit('setFalse')
        }
    },
    props:['show']
}
</script>
<style lang="scss" scoped>
.cont {
  p{
    margin: 14px 0;
  }
}
</style>
