import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showLogin: false,
    leftWidth: 0,
    province:'',
    city:'',
    showMapValue:false,
    logoImgUrl:undefined,
    showSerachValue:undefined,
  },
  getters: {
    showLogin: state => state.showLogin,
    leftWidth: state => state.leftWidth,
    province: state => state.province,
    city: state => state.city,
    showMapValue: state => state.showMapValue,
    logoImgUrl: state => state.logoImgUrl,
    showSerachValue: state => state.showSerachValue,
  },
  mutations: {
    setShowLogin: (state, value) => {
      state.showLogin = value;
    },
    setLeftWidth: (state, value) => {
      state.leftWidth = value;
    },
    setProvince: (state, value) => {
      state.province = value;
    },
    setCity: (state, value) => {
      state.city = value;
    },
    setShowMapValue: (state, value) => {
      state.showMapValue = value;
    },
    setLogoImgUrl: (state, value) => {
      state.logoImgUrl = value;
    },
    setShowSerachValue: (state, value) => {
      state.showSerachValue = value;
    },
    
  },
  actions: {
    SET_SHOWLOGIN({ commit }, status) {
      commit("setShowLogin", status);
    },
    SET_LEFTWIDTH({ commit }, status) {
      commit("setLeftWidth", status);
    },
    SET_PROVINCE({ commit }, status) {
      commit("setProvince", status);
    },
    SET_CITY({ commit }, status) {
      commit("setCity", status);
    },
    SET_SHOWMAPVALUE({ commit }, status) {
      commit("setShowMapValue", status);
    },
    SET_LOGOIMGURL({ commit }, status) {
      commit("setLogoImgUrl", status);
    },
    SET_SHOWSERACHVALUE({ commit }, status) {
      commit("setShowSerachValue", status);
    },
    
  },
  modules: {},
});
