import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/MenhuIndex.vue"),
    // component: () => import('../views/HomeView.vue')
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue"),
  },
  {
    path: "/projectIndex",
    name: "projectIndex",
    component: () => import("../views/homeIndex/index.vue"),
  },
  {
    path: "/applyIndex",
    name: "ApplyIndex",
    component: () => import("../views/homeIndex/applyIndex.vue"),
  },
  {
    path: "/menhu",
    name: "menhu",
    component: () => import("../views/MenhuIndex.vue"),
  },
  {
    path: "/detailsList",
    name: "detailsList",
    component: () => import("../views/DetailsList.vue"),
  },
  {
    path: "/detailsItem",
    name: "detailsItem",
    component: () => import("../views/DetailsItem.vue"),
  },
  {
    path: "/serachList",
    name: "serachList",
    component: () => import("../views/SerachList.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/myApply",
    name: "myApply",
    component: () => import("../views/MyApply.vue"),
  },
  {
    path: "/personalCenter",
    name: "personalCenter",
    component: () => import("../views/PersonalCenter.vue"),
  },
  {
    path: "/authenhfive",
    name: "authenhfive",
    component: () => import("../views/AuthenHfive.vue"),
  },
  {
    path: "/auth_result",
    name: "auth_result",
    component: () => import("../views/AuthResult.vue"),
  },
  {
    path: "/workPost",
    name: "workPost",
    component: () => import("../views/workPost/index.vue"),
  },
  {
    path: "/workPostDetails",
    name: "workPostDetails",
    component: () => import("../views/workPost/details.vue"),
  },
  {
    // 人脸识别的H5页面
    path: "/ifram",
    name: "ifram",
    component: () => import("../views/ifram/index.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
import { getUserLogo } from "@/api/pc";
router.beforeEach((to, from, next) => {
  // 判断是不是首页，只有首页有地址
  let routeIndex = ["/index", "/menhu"];
  if (routeIndex.indexOf(to.path) > -1) {
    store.dispatch("SET_SHOWMAPVALUE", true);
  } else {
    store.dispatch("SET_SHOWMAPVALUE", false);
  }
  // 如果地址是报名的页面
  let applyIndex = ["/projectIndex", "/applyIndex"];
  if (applyIndex.indexOf(to.path) > -1) {
    // 判断是不是同一个项目，如果是，就用已有的图标和logo
    if (
      localStorage.projectLogo &&
      localStorage.projectId == to.query.projectId
    ) {
      store.dispatch("SET_LOGOIMGURL", localStorage.projectLogo);
      setLinkIcon(localStorage.iconUrl);
    } else {
      localStorage.projectId = to.query.projectId
      // 获取用户自己的icon和logo
      getUserLogo({ projectId: localStorage.projectId }).then((res) => {
        localStorage.projectLogo = res.data.memberLogo
          ? res.data.memberLogo
          : require("@/assets/images/loginlogo.png");
        localStorage.iconUrl = res.data.tabs
          ? res.data.tabs
          : require("@/assets/icon.png");
        store.dispatch("SET_LOGOIMGURL", localStorage.projectLogo);
        setLinkIcon(localStorage.iconUrl);
      });
    }
  } else {
    store.dispatch("SET_LOGOIMGURL", require("@/assets/logon.png"));
    setLinkIcon(require("@/assets/icon.png"));
  }
  // 判断是不是报名，个人中心，这三个页面不需要搜索
  let applyIndexArry = ["/projectIndex", "/applyIndex", "/personalCenter"];
  if (applyIndexArry.indexOf(to.path) > -1) {
    store.dispatch("SET_SHOWSERACHVALUE", false);
  } else {
    store.dispatch("SET_SHOWSERACHVALUE", true);
  }
  next();
});
function setLinkIcon(dataUrl) {
  let href = dataUrl;
  // 创建新的 link 标签
  var newLink = document.createElement("link");
  newLink.rel = "icon";
  newLink.type = "image/x-icon";
  newLink.href = href;
  // 获取并移除原有的 favicon link（如果有多个，可能需要更精确的选择器）
  var oldLinks = document.querySelectorAll('link[rel="icon"]');
  for (var i = 0; i < oldLinks.length; i++) {
    oldLinks[i].parentNode.removeChild(oldLinks[i]);
  }
  // 将新创建的 link 插入到 head 中
  document.head.appendChild(newLink);
}
export default router;
