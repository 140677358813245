
<template>
    <el-dialog :title="modalTitle" :visible.sync="dialogTableVisible" @close="setFalse" class="dialogModal"
        :width="modalWidth">
        <div class="formDataAuto">
            <slot name="content"></slot>
        </div>
        <slot name="contenttTow"></slot>
        <span slot="footer" class="dialog-footer" v-if="showFooter">
            <el-button size="small" @click="setFalse">取 消</el-button>
            <el-button size="small" type="primary" @click="serveBtn">确 定</el-button>
        </span>
        <div slot="footer" class="dialog-footer" v-if="showFooterTow">
            <slot name="footer-content"></slot>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name:'MyModal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: '提示'
        },
        modalWidth: {
            type: String,
            default: '30%'
        },
        showFooter: {
            type: Boolean,
            default: false
        },
        showFooterTow: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        show(val) {
            this.dialogTableVisible = val;
        },
    },
    data() {
        return {
            dialogTableVisible: false,
        }
    },
    methods: {
        /** 关闭弹窗 */
        setFalse() {
            this.$emit('setFalse', false)
        },
        /** 点击确定按钮 */
        serveBtn() {
            this.$emit('setServeBtn')
        }
    }
}
</script>

<style lang="scss" scoped>
.formDataAuto {
    width: 100%;
    padding-right: 20px;
    max-height: 60vh;
    overflow-y: auto;
}
</style>

<style lang="scss">
.dialogModal {
    .el-dialog__header {
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        border-top: 1px solid #eee;
    }
    .el-dialog__headerbtn{
        top:15px;
    }
}
</style>



