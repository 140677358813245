import Cookies from 'js-cookie'

const TokenKey = 'Web-Token'
const TokenKeyName = 'Web-Token-Name'
const Avatar = 'avatar'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  delete localStorage.userInfo
  return Cookies.remove(TokenKey)
}

export function getUserName() {
  return Cookies.get(TokenKeyName)
}

export function setUserName(name) {
  return Cookies.set(TokenKeyName, name)
}

export function removeUserName() {
  return Cookies.remove(TokenKeyName)
}

export function setAvatar(name) {
  return Cookies.set(Avatar, name)
}
export function getAvatar() {
  return Cookies.get(Avatar)
}