<template>
  <div id="app" @scroll="handleScroll">
   <template v-if="headNum == 4">
      <div class="h5-cont">
        <router-view />
      </div>
    </template>
    <template v-else>
      <myHeaderTow></myHeaderTow>
      <div class="content-box">
        <router-view />
        <myFooter></myFooter>
      </div>
    </template>
  </div>
</template>
<script>
import myHeaderTow from "@/components/myHeaderTow";
import myFooter from "@/components/myFooter";
export default {
  name: "App",
  data() {
    return {
      headNum: 1,
    };
  },
  components: { myHeaderTow ,myFooter},
  // 监听,当路由发生变化的时候执行
  watch: {
    $route: "getPath",
  },
  // created(){
    
  // },
  methods: {
    getPath() {
      let arr3 = ["/authenhfive",'/ifram', '/auth_result', '/login', '/register'];
      if (arr3.indexOf(this.$route.path) > -1) {
        this.headNum = 4;
      } else {
        this.headNum = 1;
      }
    },
    handleScroll(event) {
      this.$store.dispatch("SET_LEFTWIDTH", event.target.scrollLeft);
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: auto;
  background-color: #e8ecf3;
}
.footer{
  font-size: 12px;
  color: #999;
  padding: 5px 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 3px;
}
.app-content {
  min-width: 1306px;
  margin: 0 auto;
  height: calc(100% - 50px);
  overflow-y: auto;
}
.h5-cont {
  width: 100%;
  height: 100%;
  // padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.content-box,
.content-gr-box {
  width: 100%;
  height: calc(100% - 50px);
}
.content-box {
  height: calc(100% - 50px);
  min-width: 1400px;
  box-sizing: border-box;
  overflow-y: auto;
}
.content-gr-box {
  min-width: 1400px;
}
</style>
