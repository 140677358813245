<template>
  <div class="footer" @click="openIpc">赣ICP备2024032590号-2</div>
</template>

<script>
export default {
  name: "myFooter",
  methods: {
    openIpc() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>
<style>
.footer:hover{
    color: #4069ff;
    cursor: pointer;
}
</style>
