import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import '@/assets/css/index.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import MyModal from "@/components/MyModal/index.vue"
import ZhuceXieyi from "@/components/ZhuceXieyi.vue"
import GerenxinxiBaohuXieyi from "@/components/GerenxinxiBaohuXieyi.vue"
import modal from "@/utils/modal"
import myPagination from "@/components/Pagination/index.vue";

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('MyModal', MyModal)
Vue.component('ZhuceXieyi', ZhuceXieyi)
Vue.component('GerenxinxiBaohuXieyi', GerenxinxiBaohuXieyi)
Vue.component('myPagination', myPagination)


Vue.prototype.$modal = modal

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
